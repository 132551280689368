<template>
  <div>
    <back-btn />
    <v-container>
      <v-row justify="center">
        <v-col cols="8">
          <patient-form @emitData="submitData" :patient="dotsPatientHandler"  />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PatientForm from "@/components/dots/PatientForm";
import BackBtn from "@/components/navigation/BackBtn.vue";

export default {
  created() {
    this.$store.dispatch("Patient/getPatient", this.$route.params.patient_id);
  },
  computed: {
    ...mapGetters("Patient", ["dotsPatientHandler"]),
   
  },
  components: {
    PatientForm,
    BackBtn,
  },
  methods: {
    submitData(data) {
      this.$store.dispatch("DotsPatient/save", {
        phone: data.phone,
        body_weight: data.body_weight,
        volunteer_id: data.volunteer_id,
        dots_startdate: data.dots_startdate,
        dots_app_status: data.dots_app_status,
        patient_id: this.$route.params.patient_id,
      }) .then(() => (
        this.$router.push({
          name: "enroll",
          params: {
            id: this.$route.params.patient_id,
          },
        })
          ));
          
    },
  },
};
</script>

<style></style>
