<template>
  <div>
    <v-card class="card--shadow">
      <v-card-title class="purple accent-4">
        <div class="subtitle-1 white--text">DOT Patient Form</div>
      </v-card-title>
      <v-card-text class="pt-4">
        <form-require-noti />
        <v-row>
          <v-col lg="4">
            <label>Name <require-sign /></label>
            <v-text-field
              v-model.trim="patient.name"
              type="text"
              outlined
              dense
              disabled
            ></v-text-field>
          </v-col>
          <v-col lg="4">
            <label>DOB <require-sign /></label>
            <v-text-field
              v-model.trim="patient.dob"
              type="text"
              outlined
              dense
              disabled
            ></v-text-field>
          </v-col>
          <v-col lg="4">
            <label>Address <require-sign /></label>
            <v-text-field
              v-model.trim="patient.address"
              type="text"
              outlined
              dense
              disabled
            ></v-text-field>
          </v-col>

          <v-col lg="4">
            <label>Sex <require-sign /></label>
            <v-radio-group v-model="patient.sex" row disabled>
              <v-radio label="Male" value="1"></v-radio>
              <v-radio label="Female" value="2"></v-radio>
            </v-radio-group>
          </v-col>

          <v-col lg="4">
            <label>TB Code</label>
            <v-text-field
              v-model.trim="patient.tb_code"
              type="number"
              outlined
              dense
              disabled
            ></v-text-field>
          </v-col>
          <v-col lg="4">
            <label>TB Year</label>
            <v-text-field
              v-model.trim="patient.tb_year"
              type="text"
              outlined
              dense
              disabled
            ></v-text-field>
          </v-col>
          <v-col lg="4">
            <label>Type of TB</label>
            <v-text-field
              :value="getTypeofTB(patient.type_of_tb)"
              type="text"
              outlined
              dense
              disabled
            ></v-text-field>
          </v-col>
          <v-col lg="4">
            <label>Treatment Regimen</label>
            <v-text-field
              :value="getRegiment(patient.mdrtb_regiment.mdrtb_regiment_id)"
              type="text"
              outlined
              dense
              disabled
            ></v-text-field>
          </v-col>
          <v-col lg="4">
            <label>Treatment Start Date</label>
            <v-text-field
              v-model.trim="patient.treatment_startdate"
              type="text"
              outlined
              dense
              disabled
            ></v-text-field>
          </v-col>
          <v-col lg="4">
            <label>Phone</label>
            <v-text-field
              v-model.trim="patient.phone"
              type="text"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col lg="4">
            <label>Body Weight (KG)</label>
            <v-text-field
              v-model.trim="patient.body_weight"
              type="number"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col lg="4">
            <label>Volunteer</label>
            <v-select
              v-model="patient.volunteer_id"
              :items="volunteers"
              item-value="id"
              dense
              outlined
            >
              <template slot="selection" slot-scope="data">
                <!-- HTML that describe how select should render selected items -->
                {{ data.item.code }} ({{ data.item.name }})
              </template>
              <template slot="item" slot-scope="data">
                <!-- HTML that describe how select should render items when the select is open -->
                {{ data.item.code }} ({{ data.item.name }})
              </template>
            </v-select>
          </v-col>
          <v-col lg="4">
            <label>DOTS Startdate</label>
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="patient.dots_startdate"
                  outlined
                  dense
                  readonly
                  persistent-hint
                  prepend-inner-icon="mdi-calendar"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="patient.dots_startdate"
                no-title
                @input="menu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col md="4">
            <label>DOTS App Status</label>

            <v-select
              v-model="patient.dots_app_status"
              :items="[
                { value: 1, text: `No` },
                { value: 2, text: `Yes` },
              ]"
              item-text="text"
              item-value="value"
              dense
              outlined
            ></v-select>
          </v-col>
        </v-row>
       
      </v-card-text>
      <v-card-actions class="px-4 pb-4">
        <v-spacer></v-spacer>

        <v-btn class="form-btn" @click="emitData" :loading="loading">
          <v-icon left>mdi-chevron-right-circle-outline</v-icon>Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import FormRequireNoti from "@/components/notification/FormRequireNoti";
import RequireSign from "@/components/notification/RequireSign";

export default {
  components: { FormRequireNoti, RequireSign },

  props: {
    patient: {
      type: Object,
      default: () => {
        return {
          name: null,
          age: null,
          address: null,
          phone: null,
          sex: null,
          volunteer: null,
          body_weight: null,
          tb_code: null,
          tb_year: null,
          type_of_tb: null,
          treatment_startdate: null,
          dots_startdate: null,
          mdrtb_regiment: {
            mdrtb_regiment_id: 1,
            dots_app_status: "",
          },
        };
      },
    },
  },
  methods: {
    emitData() {
      if (this.checkError()) {
        this.$emit("emitData", this.patient);
      }
    },
    checkError() {
      var error = ``;

      if (this.patient.phone == null || this.patient.phone.length == 0)
        error += "Phone is required \n";
      else if (
        this.patient.phone.length != 11 &&
        this.patient.phone.length != 10 &&
        this.patient.phone.length != 9
      )
        error += "Phone is invalid \n";

      if (
        this.patient.body_weight == null ||
        this.patient.body_weight.length == 0
      )
        error += "Body Weight is required \n";
      else if (this.patient.body_weight.length > 2)
        error += "Body Weight should be two digits \n";

      if (
        this.patient.volunteer_id == null ||
        this.patient.volunteer_id.length == 0
      )
        error += "Volunteer is required \n";
      if (
        this.patient.dots_app_status == null ||
        this.patient.dots_app_status.length == 0
      )
        error += "DOTS App Status is required \n";
      if (
        this.patient.dots_startdate == null ||
        this.patient.dots_startdate.length == 0
      )
        error += "DOTS Start Date is required \n";
      else if (
        new Date(this.patient.dots_startdate) <
        new Date(this.patient.treatment_startdate)
      )
        error +=
          "DOTS Start Date should be larger than or equal to treatment startdate \n";

      if (error.length > 0) this.$toast.error(error);

      return error.length == 0 ? true : false;
    },
    getTypeofTB(id) {
      var text = "";
      if (this.typeOfTBOpt) {
        this.typeOfTBOpt.map((val) => {
          if (val.id == id) {
            text = val.text;
          }
        });
      }
      return text;
    },
    getRegiment(id) {
      var text = "";
      if (this.mdrTbRegs) {
        this.mdrTbRegs.map((val) => {
          if (val.id == id) {
            text = val.text;
          }
        });
      }

      return text;
    },
  },
  computed: {
    ...mapState("InfoData", ["mdrTbRegs", "typeOfTBOpt"]),
    ...mapState("Volunteer", ["volunteers"]),
    ...mapState("Loading", ["loading"]),
  },
  created() {
    this.$store.dispatch("Volunteer/getVolunteers");
  },
  data() {
    return {
      menu: null,
    };
  },
};
</script>

<style scoped>
.v-card {
  margin: 20px 0px !important;
}
</style>
